<template>
  <div>
    <a-form layout="inline" class="ph-20 mb-20">
      <a-form-item label="问卷名称">
        <a-input v-model="form.title" :allow-clear="true" placeholder="请输入问卷名称"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="onSearch">查询</a-button>
        <a-button type="default" @click="onReset(true)">重置</a-button>
      </a-form-item>
    </a-form>

    <div class="ph-20 full-width">
      <a-table ref="tableRef"
               :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="fetching"
               :scroll="{x: '100%'}"
               bordered
               row-key="rowId"
               class="table small-cell-table">
        <template slot="answer_url" slot-scope="text">
          <a :href="text" target="_blank">{{ text }}</a>
        </template>

        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="viewAnswerDetail(record)">作答详情</a-button>
          <!--<a-button type="link" @click="viewQRCode(record)">二维码</a-button>-->
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>

    <QRModal ref="qRModalRef"/>
  </div>
</template>

<script>
import table from '@/mixins/table';

import { getSurveyList } from './api';

import QRModal from './components/QRModal.vue';

const columns = [
  {
    title: '问卷ID',
    dataIndex: 'sn',
    key: 'sn',
    width: '150px',
    align: 'center',
    fixed: 'left',
  },
  {
    title: '问卷名称',
    dataIndex: 'project_name',
    key: 'project_name',
    width: '220px',
    align: 'center',
  },
  {
    title: '发布时间',
    dataIndex: 'publish_at',
    key: 'publish_at',
    width: '180px',
    align: 'center',
  },
  {
    title: '问卷链接',
    dataIndex: 'answer_url',
    key: 'answer_url',
    width: '220px',
    align: 'center',
    autoExpand: true,
    scopedSlots: { customRender: 'answer_url' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: '160px',
    align: 'center',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
  },
];

export default {
  name: 'List',
  mixins: [table],
  components: {
    QRModal,
  },
  data() {
    return {
      defaultForm: {
        title: '',
      },

      originColumns: columns,
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    getData(page, pageSize) {
      this.executeGetData(async () => {
        const params = {
          page,
          per_page: pageSize,
          title: this.form.title || '',
        };

        const data = await getSurveyList(params);
        if (!data || data.error_code) {
          this.$message.error(data?.message || '获取列表失败');
          return Promise.reject(data);
        }
        this.data = (data?.data || []).map((i, index) => ({
          ...i,
          rowId: (page - 1) * pageSize + index + 1,
        }));

        return Promise.resolve(data);
      });
    },

    viewAnswerDetail(record) {
      this.$router.push({
        path: './result',
        query: {
          sn: record.sn,
          name: record.project_name || '',
        },
      });
    },
    viewQRCode(record) {
      if (this.$refs.qRModalRef?.show) {
        this.$refs.qRModalRef.show({
          qrUrl: record.qr_code,
        });
      }
    },
  },
};
</script>
